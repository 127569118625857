<template>
  <el-dialog
    v-drag
    title="新增"
    :visible.sync="dialogVisible"
    width="800px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">

        <div class="form-item">
          <label class="lable"><span class="red">* </span>开户银行</label>
          <el-form-item prop="swiftCode">
            <base-select-common
              v-model="form.swiftCode"
              title="开户行"
              :type="15"></base-select-common>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red"></span>开户分行</label>
          <el-form-item prop="bankBranchName">
            <el-input
              v-model="form.bankBranchName"
              placeholder="请输入账户名称"
            ></el-input>
          </el-form-item>
        </div>

        <div class="form-item">
          <label class="lable"><span class="red">* </span>账户名称</label>
          <el-form-item prop="accountName">
            <el-input
              v-model="form.accountName"
              placeholder="请输入账户名称"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>账号持有人</label>
          <el-form-item prop="accountHolder">
            <el-input
              v-model="form.accountHolder"
              placeholder="请输入账号持有人"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>账号</label>
          <el-form-item prop="accountNumber">
            <el-input
              v-model="form.accountNumber"
              oninput="value=value.replace(/[^\d]/g,'')"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>账户类型</label>
          <el-form-item prop="type">
            <el-select
              v-model="form.type"
              placeholder="请选择类别"
            >
              <el-option
                v-for="item in typeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>币制</label>
          <el-form-item prop="currency">
            <base-select-common
              v-model="form.currency"
            ></base-select-common>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>状态</label>
          <el-form-item prop="isActived">
            <el-switch
              v-model="form.isActived"
              active-color="#2395F3"
              inactive-color="#f1f1f1"
            >
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-item" style="width: 100%">
          <label class="lable">备注</label>
          <el-form-item prop="remark">
            <el-input
              v-model="form.remark"
              style="width: 100%"
              placeholder="请输入备注内容"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
    <CommDialog
      :dialogVisible="dialogVisible2"
      :dataColumn="commOptions"
      title="币制"
      :type="7"
      @ok="cancel2"
      @cancel="cancel2"
      @dataChange="dataChange"
    ></CommDialog>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { getSysParams } from '@/api/system'

import { insertBankAccount } from '@/api/fee'
const BaseSelectCommon = () => import('@/components/base/BaseSelectCommon.vue')
const CommDialog = () => import('@/components/dialog/CommDialog')
export default {
  components: { CommDialog, BaseSelectCommon },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        accountName: '',
        accountNumber: '',
        accountHolder: '',
        type: '',
        currency: '',
        isActived: true,
        remark: ''
      },
      rules: {
        swiftCode: [
          { required: true, message: '请输入开户银行', trigger: 'change' }
        ],
        accountName: [{ required: true, message: '请输入账户名称', trigger: 'change' }],
        accountNumber: [
          { required: true, message: '请输入账号', trigger: 'change' }
        ],
        accountHolder: [
          { required: true, message: '请输入账号持有人', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择账户类型', trigger: 'change' }
        ],
        currency: [
          { required: true, message: '请选择币制', trigger: 'change' }
        ]
      },
      dialogVisible2: false,
      commData: [],
      typeOption: [
        {
          value: 0,
          label: '银行卡'
        }
      ],
      sourceOption: [],
      destinationOption: []
    }
  },
  computed: {
    ...mapGetters(['userRole', 'addressDropOption', 'userTypeOptions', 'sign']),
    userRoleIdOption: function() {
      const arr = []
      this.userRole &&
        this.userRole.map((item) => {
          arr.push({
            value: item.id,
            label: item.userRoleNameCH
          })
        })
      return arr
    },
    commOptions: function() {
      const arr = []
      this.commData.map((item) => {
        arr.push({
          label: item.description,
          value: item.dbValue
        })
      })
      return arr
    }
  },
  watch: {
    dialogVisible: function(val) {
      if (val) {
        if (this.editData.id) {
          this.form = {
            ...this.editData
          }
        } else {
          this.form = {
            accountName: '',
            accountNumber: '',
            accountHolder: '',
            type: '',
            currency: '',
            isActived: true,
            remark: ''
          }
        }
      }
    }
  },
  created() {
    this.getCommData()
  },
  methods: {
    cancel() {
      this.$refs['form']?.resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            insertBankAccount(this.form).then((res) => {
              if (res.success) {
                this.$message.success('新增成功')
                this.$refs[formName]?.resetFields()
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
              }
            })
        }
      })
    },
    addClass() {
      this.dialogVisible2 = true
    },
    cancel2() {
      this.dialogVisible2 = false
    },
    getCommData() {
      getSysParams({ type: 7 }).then((res) => {
        console.log(res)
        if (res.success) {
          const arr = []
          res.data &&
            res.data[0].value &&
            res.data[0].value.map((item) => {
              arr.push(item)
            })
          this.commData = arr
        }
      })
    },
    dataChange() {
      this.getCommData()
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .form-item {
    width: calc(50% - 20px);
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    &:nth-child(2n) {
      margin-left: 40px;
    }
  }
  &:deep(.el-select) {
    width: 100%;
  }
  .addClass {
    position: absolute;
    width: 84px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 40px;
    margin-left: 10px;
    cursor: pointer;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.title {
  margin-top: 30px;
  margin-block: 16px;
}
</style>
