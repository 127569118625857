<template>
  <div class="view-contanier">
    <div class="opt">
      <div class="btn" @click="add">
        <svg-icon className="icon" iconClass="add" />新增
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
      >
        <template slot="isActived" slot-scope="row">
          <div
            class="circle"
            :style="{
              'background-color': row.scope.isActived ? ' #35BA83' : '#FE453D',
            }"
          ></div>
          {{ row.scope.isActived ? "使用中" : "未使用" }}
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <el-switch
              v-model="row.scope.isActived"
              active-color="#2395F3"
              inactive-color="#CDCDCD"
              @change="changeStatus(row.scope)"
            >
            </el-switch
            ><span
              class="status"
              :style="{
                color: row.scope.isActived ? '#2395F3' : '#999999',
              }"
            >{{ row.scope.isActived ? "启用" : "停用" }}</span
            >
            <el-button
              type="text"
              @click="customers(row.scope)"
            >下属客户</el-button
            >
          </div>
        </template>
      </BaseTable>
    </div>
    <add-accounts-receivable-dialog
      :dialogVisible="dialogVisible"
      @ok="ok"
      @cancel="cancel"
    ></add-accounts-receivable-dialog>
    <subordinate-customers-dialog :bankAccountId="editData.id" :dialogFormVisible="dialogFormVisible" @ok="ok2" @cancel="cancel2"></subordinate-customers-dialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import { getBankAccountPageList, switchBankAccount } from '@/api/fee'
import { mapGetters } from 'vuex'
import AddAccountsReceivableDialog from './components/AddAccountsReceivableDialog.vue'
import SubordinateCustomersDialog from './components/SubordinateCustomersDialog.vue'
import { myMixins } from '@/mixins'
export default {
  name: 'AccountsReceivable',
  components: {
    BaseTable,
    AddAccountsReceivableDialog,
    SubordinateCustomersDialog
  },
  mixins: [myMixins],
  data() {
    return {
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'accountName',
            label: '账户名称',
            width: '120px',
            fixed: 'left'
          },
          {
            filed: 'accountHolder',
            label: '开户人',
            width: '120px'
          },
          {
            filed: 'accountNumber',
            label: '银行账号',
            width: '200px'
          },
          {
            filed: 'type',
            label: '账户类型',
            width: '120px',
            formatter: (row) => {
              return row.type === 0 ? '银行卡' : ''
            }
          },
          {
            filed: 'currency',
            label: '币制',
            width: '100px'
          },
          {
            filed: 'isActived',
            label: '状态',
            width: '100px',
            isCustom: true
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '100px'
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '180px',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : ''
          },
          {
            filed: 'updatedName',
            label: '修改人',
            width: '100px'
          },
          {
            filed: 'updatedOn',
            label: '修改时间',
            width: '180px',
            formatter: (row) =>
              row.updatedOn ? row.updatedOn.slice(0, row.updatedOn.length - 3) : ''
          },
          {
            filed: 'remark',
            label: '备注',
            width: '300px'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '200px',
            isCustom: true,
            fixed: 'right'
          }
        ],
        columns: [],
        total: 0
      },
      height: 0,
      dialogVisible: false,
      dialogFormVisible: false,
      editData: {}
    }
  },
  computed: {
    ...mapGetters(['addressDropOption'])
  },
  created() {
    this.getList(this.pagerConfig)
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
  },
  methods: {
    getList(params) {
      if (!params) {
        params = this.pagerConfig
      }
      this.loading = true
      try {
        getBankAccountPageList(params).then((res) => {
          if (res.success) {
            this.loading = false
            this.tableData.total = res.data.total
            this.tableData.columns = res.data.list
          } else {
            this.loading = false
            this.$message.error(res.errorMessage)
          }
        })
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    update(row) {
      this.editData = row
      this.dialogVisible = true
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.getList(params)
    },
    add() {
      this.dialogVisible = true
    },
    ok() {
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = params
      this.getList(params)
      this.cancel()
    },
    cancel() {
      this.dialogVisible = false
      this.editData = {}
    },
    async changeStatus(row) {
      try {
        const res = await switchBankAccount({ id: row.id })
        if (res.success) {
          this.$message.success('设置成功')
          this.getList(this.pagerConfig)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    customers(row) {
      this.dialogFormVisible = true
      this.editData = row
    },
    ok2() {
      this.cancel2()
    },
    cancel2() {
      this.dialogFormVisible = false
      this.editData = {}
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .btn {
    width: 80px;
    height: 30px;
    background: #2395f3;
    border-radius: 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
  }
  .icon {
    font-size: 20px;
    vertical-align: text-top;
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .table-opt:deep(.el-button) {
    padding: 0;
  }
  .filter {
    width: 438px;
  }
  .status {
    width: 28px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 20px;
    margin-left: 5px;
    margin-right: 31px;
  }
}
.select {
  width: 100%;
  &:deep(.el-select) {
    width: 100%;
  }
}
</style>
