var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "新增",
        visible: _vm.dialogVisible,
        width: "800px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("开户银行"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "swiftCode" } },
                    [
                      _c("base-select-common", {
                        attrs: { title: "开户行", type: 15 },
                        model: {
                          value: _vm.form.swiftCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "swiftCode", $$v)
                          },
                          expression: "form.swiftCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }),
                    _vm._v("开户分行"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "bankBranchName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入账户名称" },
                        model: {
                          value: _vm.form.bankBranchName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bankBranchName", $$v)
                          },
                          expression: "form.bankBranchName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("账户名称"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "accountName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入账户名称" },
                        model: {
                          value: _vm.form.accountName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "accountName", $$v)
                          },
                          expression: "form.accountName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("账号持有人"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "accountHolder" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入账号持有人" },
                        model: {
                          value: _vm.form.accountHolder,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "accountHolder", $$v)
                          },
                          expression: "form.accountHolder",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("账号"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "accountNumber" } },
                    [
                      _c("el-input", {
                        attrs: {
                          oninput: "value=value.replace(/[^\\d]/g,'')",
                          placeholder: "请输入账号",
                        },
                        model: {
                          value: _vm.form.accountNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "accountNumber", $$v)
                          },
                          expression: "form.accountNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("账户类型"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择类别" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.typeOption, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("币制"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "currency" } },
                    [
                      _c("base-select-common", {
                        model: {
                          value: _vm.form.currency,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "currency", $$v)
                          },
                          expression: "form.currency",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("状态"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "isActived" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#2395F3",
                          "inactive-color": "#f1f1f1",
                        },
                        model: {
                          value: _vm.form.isActived,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isActived", $$v)
                          },
                          expression: "form.isActived",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item", staticStyle: { width: "100%" } },
                [
                  _c("label", { staticClass: "lable" }, [_vm._v("备注")]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "remark" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入备注内容" },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
      _c("CommDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible2,
          dataColumn: _vm.commOptions,
          title: "币制",
          type: 7,
        },
        on: {
          ok: _vm.cancel2,
          cancel: _vm.cancel2,
          dataChange: _vm.dataChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }