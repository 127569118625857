<template>
  <el-dialog
    v-drag
    title="下属客户"
    :visible.sync="dialogFormVisible"
    width="520px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    :before-close="cancel"
  >
    <div class="content">
      <div class="list">
        <ul class="header">
          <li>
            <div class="cell1">客户名称</div>
            <div class="cell2">创建时间</div>
            <div class="cell3" @click="add">
              <i class="el-icon-circle-plus-outline" />
              <span style="margin-left: 4px">新增</span>
            </div>
          </li>
        </ul>
        <ul class="body">
          <li v-for="item in lists" :key="item.id">
            <div class="cell1">
              <el-tooltip class="item" effect="dark" :content="item.nameCH" placement="bottom">
                <span>{{ item.nameCH }}</span>
              </el-tooltip>
            </div>
            <div class="cell2">{{ item.createdOn ? item.createdOn.slice(0, item.createdOn.length - 3) : '' }}</div>
            <div class="cell3"><i class="el-icon-delete" @click="remove(item.id)" /></div>
          </li>
        </ul>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok">确 定</div>
    </span>
    <add-customer-dialog
      :dialogFormVisible="dialogFormVisible2"
      :bankAccountId="bankAccountId"
      @ok="ok2"
      @cancel="cancel2"
    ></add-customer-dialog>
  </el-dialog>
</template>
<script>
import { getBankAccountCompany, deleteBankAccountCompany } from '@/api/fee'
import AddCustomerDialog from './AddCustomerDialog.vue'
export default {
  components: { AddCustomerDialog },
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    bankAccountId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      form: {
        companyId: '',
        creditAmount: '',
        reason: ''
      },
      lists: [],
      dialogFormVisible2: false
    }
  },
  computed: {},
  watch: {
    dialogFormVisible(val) {
      if (val) {
        this.getList()
      }
    }
  },
  methods: {
    ok() {
      this.$emit('ok')
    },
    cancel() {
      this.$emit('cancel')
    },
    add() {
      this.dialogFormVisible2 = true
    },
    ok2() {
      this.getList()
      this.cancel2()
    },
    cancel2() {
      this.dialogFormVisible2 = false
    },
    async remove(id) {
      try {
        const res = await deleteBankAccountCompany({ id })
        if (res.success) {
          this.$message.success('删除成功')
          this.getList()
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getList() {
      try {
        const res = await getBankAccountCompany({
          bankAccountId: this.bankAccountId
        })
        if (res.success) {
          this.lists = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  display: flex;
  .list {
    width: 420px;
    height: 240px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #eeeeee;
    .header {
      width: 100%;
      height: 40px;
      background: #f7f7f7;
      border-radius: 4px 4px 0px 0px;
      opacity: 1;
      border-bottom: 1px solid #eeeeee;
      text-align: center;
    }
    .body {
      width: 100%;
      height: 200px;
      overflow-x: hidden;
      overflow-y: auto;
      border-radius: 4px 4px 0px 0px;
      opacity: 1;
      text-align: center;
      li {
        border-bottom: 1px solid #eeeeee;
      }
    }
    li {
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
      display: flex;
    }
    .cell1 {
      width: 139px;
      border-right: 1px solid #eeeeee;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .cell2 {
      width: 159px;
      border-right: 1px solid #eeeeee;
    }
    .cell3 {
      width: 120px;
      color: #2395f3;
      cursor: pointer;
    }
  }
}.footers {
    display: flex;
    justify-content: center;
    .cancel {
      width: 120px;
      height: 40px;
      background: #EEEEEE;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-right: 16px;
      cursor: pointer;
    }
    .ok {
      width: 120px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      cursor: pointer;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
/deep/.el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>
