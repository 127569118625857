var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "新增客户",
        visible: _vm.dialogFormVisible,
        width: "520px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "form" },
          [
            _c("p", { staticClass: "title" }, [_vm._v("选择客户")]),
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                attrs: { filterable: "", placeholder: "请选择客户" },
                model: {
                  value: _vm.companyId,
                  callback: function ($$v) {
                    _vm.companyId = $$v
                  },
                  expression: "companyId",
                },
              },
              _vm._l(_vm.companyOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c("div", { staticClass: "ok flex_c_c", on: { click: _vm.ok } }, [
            _vm._v("确 定"),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }