<template>
  <el-dialog
    v-drag
    title="新增客户"
    :visible.sync="dialogFormVisible"
    width="520px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    :before-close="cancel"
  >
    <div class="content">
      <div class="form">
        <p class="title">选择客户</p>
        <el-select
          v-model="companyId"
          filterable
          style="width: 100%"
          placeholder="请选择客户"
        >
          <el-option
            v-for="item in companyOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
import { insertBankAccountCompany } from '@/api/fee'
import { getCompanyPages } from '@/api/system'
export default {
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    bankAccountId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      companyId: '',
      companyOptions: []
    }
  },
  computed: {},
  watch: {
    dialogFormVisible(val) {
      if (val) {
        this.getList()
      }
    }
  },
  methods: {
    async ok() {
      if (!this.companyId) {
        this.$message.error('请选择客户')
        return
      }
      const params = {
        bankAccountId: this.bankAccountId,
        companyId: this.companyId,
        isActived: true
      }
      try {
        const res = await insertBankAccountCompany(params)
        if (res.success) {
          this.$message.success('新增成功')
          this.$emit('ok')
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    cancel2() {
      this.dialogVisible2 = false
    },
    add() {
      this.dialogVisible2 = true
    },
    async getList() {
      try {
        const res = await getCompanyPages({
          pageIndex: 1,
          pageSize: 1000
        })
        if (res.success) {
          const arr = []
          res.data &&
            res.data.list.map((item) => {
              arr.push({
                value: item.id,
                label: `${item.code}-${item.wbName}`
              })
            })
          this.companyOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  .form {
    width: 400px;
    height: 64px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin: 0 auto;
    .title {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
  }
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/.el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>
