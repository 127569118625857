var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "下属客户",
        visible: _vm.dialogFormVisible,
        width: "520px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "list" }, [
          _c("ul", { staticClass: "header" }, [
            _c("li", [
              _c("div", { staticClass: "cell1" }, [_vm._v("客户名称")]),
              _c("div", { staticClass: "cell2" }, [_vm._v("创建时间")]),
              _c("div", { staticClass: "cell3", on: { click: _vm.add } }, [
                _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                _c("span", { staticStyle: { "margin-left": "4px" } }, [
                  _vm._v("新增"),
                ]),
              ]),
            ]),
          ]),
          _c(
            "ul",
            { staticClass: "body" },
            _vm._l(_vm.lists, function (item) {
              return _c("li", { key: item.id }, [
                _c(
                  "div",
                  { staticClass: "cell1" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: item.nameCH,
                          placement: "bottom",
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(item.nameCH))])]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "cell2" }, [
                  _vm._v(
                    _vm._s(
                      item.createdOn
                        ? item.createdOn.slice(0, item.createdOn.length - 3)
                        : ""
                    )
                  ),
                ]),
                _c("div", { staticClass: "cell3" }, [
                  _c("i", {
                    staticClass: "el-icon-delete",
                    on: {
                      click: function ($event) {
                        return _vm.remove(item.id)
                      },
                    },
                  }),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c("div", { staticClass: "ok flex_c_c", on: { click: _vm.ok } }, [
            _vm._v("确 定"),
          ]),
        ]
      ),
      _c("add-customer-dialog", {
        attrs: {
          dialogFormVisible: _vm.dialogFormVisible2,
          bankAccountId: _vm.bankAccountId,
        },
        on: { ok: _vm.ok2, cancel: _vm.cancel2 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }